<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body>
        <div class="h4 mb-1">
          รายการสมาชิก
        </div>

        <div class="d-flex flex-wrap">
          <div class="d-flex align-items-start py-50">
            <div class="btn-disable">
              วันที่สมัคร(จาก)
            </div>
            <flat-pickr
              v-model="dateStart"
              class="form-control"
              placeholder="เลือกเวลาเริ่มต้น"
            />
          </div>

          <div class="d-flex align-items-start py-50">
            <div class="btn-disable">
              วันที่สมัคร(ถึง)
            </div>
            <flat-pickr
              v-model="dateEnd"
              class="form-control"
              placeholder="เลือกเวลาเริ่มต้น"
            />
          </div>

          <!-- <div class="d-flex align-items-start py-50">
            <div class="btn-disable">
              ประเภท
            </div>
            <b-form-select v-model="search_type" :options="search_type_option" placeholder="เลือกประเภทที่จะค้นหา" />
          </div> -->

          <div class="d-flex align-items-start py-50">
            <div class="btn-disable">
              คำค้นหา
            </div>
            <input
              id="username"
              v-model="search_val"
              type="text"
              class="form-control"
              style="width: 230"
              placeholder="กรอกข้อมูล"
            >
          </div>

          <div class="d-flex align-items-start py-50">
            <button
              class="btn btn-primary ml-lg-25"
              @click="getUsers()"
            >
              ค้นหา
            </button>

            <button
              v-b-modal.exportDepositModal
              class="btn btn-warning ml-25"
            >
              ออกรายงาน EXCEL
            </button>
          </div>
          <b-modal
            id="exportDepositModal"
            title="ระบุรหัสผ่าน"
            @ok="checkExportPassword()"
          >
            <b-form-input v-model="exportPassword" />
          </b-modal>

          <div class="ml-auto py-50">
            <router-link :to="{ name: 'member-add' }">
              <button
                class="btn btn-primary"
                @click="getUsers()"
              >
                CREATE
              </button>
            </router-link>
          </div>
        </div>

        <div class="mt-3 d-flex justify-content-between align-items-center">
          <div class="row no-gutters align-items-center">
            <div class="col-6">
              <h4 class="mb-0">
                ยอดสมาชิก {{ totalRows }}
              </h4>
            </div>
            <!-- <div class="col-6 d-flex justify-content-end">
              <input
                id="username"
                v-model="search_val"
                type="text"
                class="form-control"
                style="width: 150px;"
                placeholder="ค้นหา"
              >
            </div> -->
          </div>
        </div>
      </b-card-body>

      <b-table
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            >
            {{
              scope.emptyFiltered ? "No matching records found" : "ไม่พบข้อมูล"
            }}
          </div>
        </template>
        <template #cell(detail)="data">
          <div class="text-nowrap">
            <b-button
              title="รายละเอียด"
              variant="gradient-info"
              size="sm"
              @click="$router.push({ name: 'Deposit-Withdraw', params: { id: data.item.username }})"
            >
              <i class="fas fa-address-card" />
            </b-button>
          </div>
        </template>
        <template #cell(tel)="data">
          {{ data.item.tel }}
        </template>

        <template #cell(name)="data">
          {{ data.item.name }}
          {{ data.item.surname }}
        </template>

        <template #cell(user_pass)="data">
          <button
            :id="data"
            class="btn btn-warning"
            @click="getCreditBalance(data.item.username)"
          >
            {{ data.item.username }}
          </button>
        </template>

        <template #cell(bankname)="data">
          <div class="text-center">
            <img
              :src="`/bankIcon/${data.item.bank_img}`"
              alt="bankIcon"
              height="25"
              width="25"
              class="rounded-circle"
            >
            <br>
            <small>{{ data.item.acc_no }}</small>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            id="dropdown-3"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="Success"
            variant="success"
          >
            <template #button-content>
              <i class="far fa-edit mr-50" /> จัดการ
            </template>
            <b-dropdown-item
              @click="
                $router.push({
                  name: 'history-turnover',
                  params: { id: data.item.username },
                })
              "
            ><i class="far fa-file-spreadsheet mr-1 text-info" /> Turn Over</b-dropdown-item>
            <b-dropdown-item
              @click="
                $router.push({
                  name: 'Deposit-Withdraw',
                  params: { id: data.item.username },
                })
              "
            ><i class="far fa-edit mr-1 text-warning" /> แก้ไข</b-dropdown-item>
            <b-dropdown-item><i class="fas fa-trash mr-1 text-danger" /> ลบ
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getUsers()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getUsers()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>

      <b-modal
        ref="userpass-modal"
        title="Username / Password"
        scrollable
        ok-only
      >
        <div class="row">
          <div class="col-6">
            Username: {{ itemModal.username }}
          </div>
          <div class="col-6">
            Password:
          </div>
        </div>

        <b-list-group
          v-if="wallet"
          class="my-1"
        >
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <span>Chip : {{ wallet ? wallet.balance.chip : 0 }}</span>
            <span>Point : {{ wallet ? wallet.balance.point : 0 }}</span>
            <span>Free Wheel : {{ wallet ? wallet.balance.freewheel : 0 }}</span>
          </b-list-group-item>
          <b-list-group-item
            v-for="(item, index) in wallet.wallet"
            :key="index"
            class="d-flex justify-content-between align-items-center"
          >
            <span>{{ item.label }} :</span>
            <span>
              {{ item.balance }}
            </span>
          </b-list-group-item>
        </b-list-group>
      </b-modal>

      <!-- <b-modal
        id="modal-edit"
        no-close-on-backdrop
        title="Edit Member"
        ok-only
        ok-title="Save"
        @ok="update_member"
      >
        <b-form-group
          label="Name"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-first-name"
              v-model="edit_name"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Surname"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-first-name"
              v-model="edit_surname"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Password"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-first-name"
              v-model="edit_password"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="ธนาคาร"
          label-for="vi-first-name"
        >
          <v-select
            v-model="edit_bank"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="banklist"
            :reduce="(name) => name.bankcode"
          />
        </b-form-group>
        <b-form-group
          label="เลขบัญชี"
          label-for="vi-first-name"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-first-name"
              v-model="edit_acc_no"
              placeholder=""
            />
          </b-input-group>
        </b-form-group>
      </b-modal>
      <b-modal
        id="modal-promo"
        title="Promotion"
        no-close-on-backdrop
        ok-title="save"
        @ok="Submit_promotion"
      >
        <b-form-group>
          <v-select
            v-model="pro_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="prolist"
          />
        </b-form-group>
      </b-modal> -->
    </b-card>
  </b-overlay>
</template>

<script>
import {
  // BRow, BCol,
  // BInputGroupPrepend,
  BModal,
  BButton,
  BCard,
  BTable,
  // BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  // BInputGroup,
  BFormInput,
  // BButton,
  BCardBody,
  VBToggle,
  BOverlay,
  BIconController,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BListGroup, BListGroupItem,
  // BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
import moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    // BRow,
    // BCol,
    // vSelect,
    // BInputGroupPrepend,
    BButton,
    BModal,
    BCard,
    BTable,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    // BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      search_type_option: [
        { value: 1, text: 'ยูสเซอร์เนม' },
        { value: 2, text: 'เบอร์โทร' },
        { value: 3, text: 'ชื่อลูกค้า' },
        { value: 4, text: 'เลขบัญชี' },
        { value: 5, text: 'เลขกระเป๋าคริปโต' },
      ],
      itemModal: [],
      search_type: null,
      search_val: null,
      show: true,
      prolist: [],
      pro_id: null,
      depositdata: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      banklist: [],
      update_id: null,
      edit_id: null,
      edit_password: null,
      edit_name: null,
      edit_surname: null,
      edit_bank: null,
      edit_acc_no: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        // { key: 'index', label: 'no.' },
        { key: 'detail', label: 'รายละเอียด' },
        { key: 'tel', label: 'เบอร์โทร' },
        // { key: 'username', label: 'ยูสเซอร์เนม' },
        { key: 'name', label: 'ชื่อ-นามสกุล' },
        // { key: 'type', label: 'ประเภท' },
        // { key: 'updated_at', label: 'balance' },
        // { key: 'email', label: 'email' },
        { key: 'bankname', label: 'ธนาคาร', thClass: 'text-center' },
        {
          key: 'created_at',
          label: 'สมัครเมื่อ',
          formatter: value => (value
            ? moment(value)
              .tz('Asia/Bangkok')
              .add('hours', 7)
              .format('DD-MM-YYYY HH:mm')
            : ''),
        },
        // { key: 'acc_no', label: 'เลขบัญชี' },
        // { key: 'crypto_accno', label: 'เลขกระเป๋าคริปโต' },
        // { key: 'user_pass', label: 'User / Password[พนัน]' },
        // { key: 'pro_name', label: 'promotion' },
        // { key: 'actions', label: 'จัดการ' },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      dateStart: moment().tz('Asia/Bangkok').clone().subtract(7, 'days')
        .format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      wallet: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  // created() {
  //   this.interval = setInterval(() => {
  //     // this.getUsers()
  //     // this.Get_promotionlist()
  //   }, 30000)
  // },
  // destroyed() {
  //   clearInterval(this.interval)
  // },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    // this.Get_userlist()
    this.getUsers()
    this.Get_promotionlist()
    this.Get_bank()
  },
  methods: {
    async getCreditBalance(username) {
      try {
        const { data } = await this.$http.get(`/balance/checkCreditBalance/${username}`)

        this.$refs['userpass-modal'].show()
        this.wallet = data
      } catch (error) {
        console.log(error)
      }
    },
    userpassModal(val) {
      this.$refs['userpass-modal'].show()
      this.itemModal = val
    },
    async checkExportPassword() {
      const obj = {
        password: this.exportPassword,
      }
      this.$http
        .post('/export/checkpass', obj)
        .then(response => {
          if (response.data.status === true) {
            this.Export()
          } else {
            this.$swal({
              icon: 'error',
              title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
              text: 'รหัสผ่านไม่ถูกต้อง',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }
        })
        .catch(error => console.log(error))
    },
    async Export() {
      const { data } = await this.$http.get('/export/member-no-deposit', {
        responseType: 'blob',
        params: {
          startDate: this.dateStart, endDate: this.dateEnd,
        },
      })

      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(
        new Blob([data]),
      )
      // link.setAttribute(`download', 'Export Deposit Date ${this.dateStart} - ${this.dateEnd}.xlsx`)
      link.setAttribute('download', 'ข้อมูลเบอร์โทรลูกค้า.xlsx')
      document.body.appendChild(link)
      link.click()
    },
    addpro(id) {
      this.update_id = id
      // console.log(id)
    },
    editmember(data) {
      this.edit_id = data.id
      this.edit_name = data.name
      this.edit_surname = data.surname
      this.edit_bank = data.bank
      this.edit_acc_no = data.acc_no
      // console.log(data)
    },
    update_member() {
      const params = {
        name: this.edit_name,
        password: this.edit_password,
        surname: this.edit_surname,
        bank: this.edit_bank,
        acc_no: this.edit_acc_no,
      }
      // console.log(params)
      this.$http
        .post(`/users/update_namepass/${this.edit_id}`, params)
        .then(response => {
          console.log(response)
          this.show = false
          this.getUsers()
          this.edit_name = ''
          this.edit_password = ''
          this.edit_surname = ''
          this.edit_bank = ''
          this.edit_acc_no = ''
        })
        .catch(error => console.log(error))
    },
    Get_bank() {
      this.$http
        .get('/bank/list')
        .then(response => {
          this.banklist = response.data
        })
        .catch(error => console.log(error))
    },
    Submit_promotion() {
      const params = {
        main_promotion: this.pro_id,
      }
      this.$http
        .post(`/users/update/${this.update_id}`, { params })
        .then(() => {
          this.getUsers()
          this.Success('อัพเดทโปรโมชั่น สำเร็จ')
          this.update_id = null
          this.pro_id = null
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    Get_promotionlist() {
      this.$http
        .get('/promotion/list')
        .then(response => {
          this.prolist = response.data
        })
        .catch(error => console.log(error))
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    async getUsers() {
      this.show = true
      let index = 0
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_val: this.search_val,
        search_type: this.search_type,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      await this.$http
        .get('/users/no-deposits', { params })
        .then(response => {
          this.show = false
          this.depositdata = response.data.data
          this.depositdata.forEach(items => {
            this.getBalance(items.username, index)
            index += 1
          })
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    getBalance(username, index) {
      const params = {
        username,
      }
      this.$http
        .get('/balance/getbalance', { params })
        .then(response => {
          this.show = false
          // console.log(response.data)
          this.depositdata[index].updated_at = response.data.balance
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.btn {
  box-shadow: 0 4px 8px -4px rgba(94, 86, 105, .42) !important;
}

.row {
  flex-wrap: wrap;
  flex: 1 1 auto;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.form-item-section {
  background-color: $product-details-bg;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
